import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { CentersTypes, OpeningHoursType } from 'types/centersTypes'
import Layout from 'components/Layout'
import Map from 'components/Map'
import {
  Box,
  Card,
  Circle,
  Container,
  DottedLine,
  Flex,
} from 'styles/globalStyle'
import {
  Text,
  RichText,
  Grid,
  Title,
  Button,
  Icons,
  CustomGallery,
} from 'components/theme'
import SEO from 'components/SEO'

type CentersTemplateParams = {
  data: {
    prismicCenters: {
      data: CentersTypes
    }
  }
}

const CentersTemplate: FC<CentersTemplateParams> = ({ data }) => {
  const {
    title_meta,
    description_meta,
    title,
    content,
    link_google,
    email,
    phone_number,
    address,
    image,
    opening_hours,
    coordinates,
    gallery_image,
  } = data.prismicCenters.data
  const position = {
    lat: coordinates?.latitude || 0,
    lng: coordinates?.longitude || 0,
  }
  const displayOpeningHours = (item: OpeningHoursType) => {
    if (item.opening_time.text && item.closing_time.text) {
      return `${item.opening_time.text} et ${item.closing_time.text}`
    } else if (item.opening_time.text) {
      return item.opening_time.text
    } else if (item.closing_time.text) {
      return item.closing_time.text
    } else {
      return 'Fermé'
    }
  }

  return (
    <Layout onlyFooter>
      <SEO title={title_meta.text} description={description_meta.text} />
      <Map height="270px" zoom={16} position={position} marker={position} />
      <Container mt="2.5rem" mb="6rem">
        <Grid
          columns={['1fr', '', 'repeat(2, 1fr)']}
          spacing={['2rem', '4rem', '', '8.5rem']}
        >
          <Box>
            <Text fontWeight="600" mb="0.75rem" color="primary.700">
              Bienvenue dans votre
            </Text>
            <RichText content={content} />
          </Box>
          <Box position="relative" top={['0', '', '-6rem']}>
            <Card
              maxWidth={['100%', '', '448px']}
              mx="auto"
              position="sticky"
              top="9rem"
            >
              <Box position="relative">
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt={image.alt}
                  style={{
                    height: '220px',
                  }}
                  imgStyle={{
                    borderTopLeftRadius: '1rem',
                    borderTopRightRadius: '1rem',
                  }}
                />
                {/* <Box position="absolute" bottom="0.5rem" right="0.5rem">
                  <CustomGallery images={gallery_image} />
                </Box> */}
              </Box>
              <Box padding={['1rem', '2rem 2rem 2.5rem']}>
                <Title as="h2" size="xs" mb="0.5rem">
                  {title.text}
                </Title>
                {link_google.url && (
                  <Text
                    as="a"
                    href={link_google.url}
                    color="primary.700"
                    fontWeight="500"
                    target="_blank"
                  >
                    Voir la fiche Google
                  </Text>
                )}
                <Flex alignItems="center" mt="1.5rem">
                  <Circle
                    bg="primary.25"
                    width="2rem"
                    height="2rem"
                    mr="0.75rem"
                  >
                    <Icons
                      name="map-pin"
                      width="1rem"
                      height="1rem"
                      color="primary.600"
                    />
                  </Circle>
                  <Text
                    color="primary.700"
                    style={{ textDecoration: 'underline' }}
                  >
                    {address.text}
                  </Text>
                </Flex>
                {phone_number.text && (
                  <Flex alignItems="center" mt="0.75rem">
                    <Circle
                      bg="primary.25"
                      width="2rem"
                      height="2rem"
                      mr="0.75rem"
                    >
                      <Icons
                        name="phone"
                        width="1rem"
                        height="1rem"
                        color="primary.600"
                      />
                    </Circle>
                    <Text
                      as="a"
                      href={`tel:${phone_number.text}`}
                      color="primary.700"
                      style={{ textDecoration: 'underline' }}
                    >
                      {phone_number.text}
                    </Text>
                  </Flex>
                )}
                <Flex alignItems="center" mt="0.75rem">
                  <Circle
                    bg="primary.25"
                    width="2rem"
                    height="2rem"
                    mr="0.75rem"
                  >
                    <Icons
                      name="activity"
                      width="1rem"
                      height="1rem"
                      color="primary.600"
                    />
                  </Circle>
                  <Text
                    as="a"
                    href={`mailto:${email.text}`}
                    color="primary.700"
                    style={{ textDecoration: 'underline' }}
                  >
                    {email.text}
                  </Text>
                </Flex>
                <Box my="2rem">
                  {opening_hours.map((item, idx) => (
                    <DottedLine
                      key={idx}
                      justifyContent="space-between"
                      mb="0.75rem"
                    >
                      <Text>{item.day}</Text>
                      <Text>{displayOpeningHours(item)}</Text>
                    </DottedLine>
                  ))}
                </Box>
                <Button
                  type="gradient"
                  size="xl"
                  width="calc(100% - 2.5rem)"
                  to="/appointment"
                >
                  <Icons
                    name="calendar"
                    width="1.25rem"
                    height="1.25rem"
                    mr="0.75rem"
                  />
                  Prendre rendez-vous
                </Button>
              </Box>
            </Card>
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($uid: String) {
    prismicCenters(uid: { eq: $uid }) {
      data {
        ...PrismicCentersDataTypeFragment
      }
    }
  }
`

export default CentersTemplate
